import React from 'react';
import styled from 'styled-components';
import {compose} from 'recompose';
import Link from 'gatsby-link';
import * as Components from '@p14/components';
import * as HOC from '@p14/HOC';
import config from '@p14/config';
import data from '@p14/texts/documents';
import docs from '@p14/texts/documents.json';

export const Subtitle = styled.p`
  font-family: Geometria;
  font-size: 14px;
  color: #b48b72;
  margin: 40px auto 50px;
  max-width: 470px;
`;

export const Pre = styled.p`
  font-family: Geometria;
  font-size: 12px;
  color: #9b9b9b;
`;

export const Footer = styled.p`
  margin: 50px auto 30px;
  max-width: 784px;
  font-family: Geometria;
  font-size: 12px;
  color: #000;
  line-height: 14px;
  text-align: center;
  color: ${config.colors.text};
`;

export const Documents = ({tr}) => (
  <Components.Container>
    <Components.H1>Политика конфиденциальности</Components.H1>
    <Subtitle>В соответствии с законом №152-ФЗ «О персональных данных» сообщаем, что этот сайт использует «cookies». Условия использования «cookies» см. в Правилах пользования сайтом. Продолжая использовать сайт, Вы соглашаетесь с указанными выше условиями.</Subtitle>
    <Pre dangerouslySetInnerHTML={{__html: `
Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Администрация сайта http://pirogovskaya14.com может получить о Пользователе во время использования сайта ООО «Элберт Инвестмент», его сервисов, программ и продуктов.
<br/><br/>
Использование сервисов Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования сервисов.
<br/><br/>
1. Определения терминов
<br/><br/>
1.1. В настоящей Политике конфиденциальности используются следующие термины:
<br/><br/>
1.1.1. «Администрация сайта http://pirogovskaya14.com (далее – Администрация сайта)» – уполномоченные на управление сайтом сотрудники, действующие от имени ООО «Элберт Инвестмент», которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
<br/><br/>
1.1.2. «Персональные данные» — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
<br/><br/>
1.1.3. «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
<br/><br/>
1.1.4. «Конфиденциальность персональных данных» — обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
<br/><br/>
1.1.5. «Пользователь сайта (далее Пользователь)» – лицо, имеющее доступ к Сайту посредством сети Интернет и использующее Сайт Компании.
<br/><br/>
1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
<br/><br/>
1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
<br/><br/>
2. Общие положения
<br/><br/>
2.1. Сайт под доменным именем http://pirogovskaya14.com (далее – Сайт) принадлежит ООО «Элберт Инвестмент» (119435, г. Москва, ул. М.Пироговская, д.14, стр.5)
<br/><br/>
2.2. Принимая условия настоящей Политики, Пользователь подтверждает своё согласие на сбор и обработку определённой информации в целях, установленных в настоящей Политике, и в соответствии с ней. Использование сайта http://pirogovskaya14.com означает безоговорочное согласие с настоящей Политикой и указанными в ней условиями обработки персональной информации.
<br/><br/>
2.3. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование Сайта.
<br/><br/>
2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта Компании.
<br/><br/>
2.5. Настоящая Политика конфиденциальности применяется только к сайту yesapart.com. Администрация сайта не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте ООО «Элберт Инвестмент».
<br/><br/>
2.6. При обработке персональных данных Пользователей Администрация сайта руководствуется Федеральным законом РФ «О персональных данных».
<br/><br/>
3. Цели обработки персональной информации Пользователей
<br/><br/>
3.1. Персональную информацию Пользователя Администрация сайта обрабатывает в следующих целях:
<br/><br/>
3.1.1. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта Компании, контроля качества оказания услуг, обработки запросов и заявок от Пользователя;
<br/><br/>
3.1.3. Предоставления Пользователю с его согласия специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени Компании;
<br/><br/>
3.1.4. Таргетирования рекламных материалов;
<br/><br/>
3.1.5. Проведения статистических и иных исследований, на основе обезличенных данных.
<br/><br/>
4. Предмет политики конфиденциальности
<br/><br/>
4.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при использовании Сайта
<br/><br/>
4.2. Пользователь разрешает Администрации сайта получать и обрабатывать следующие данные:
<br/><br/>
4.2.1. фамилию и имя Пользователя
<br/><br/>
4.2.2. адрес электронной почты (e-mail)
<br/><br/>
4.2.3. контактный телефон Пользователя
<br/><br/>
4.2.4. страну и город проживания
<br/><br/>
4.3. В ходе использования Сайта Пользователь разрешает Администрации сайта получать и обрабатывать следующие Данные:
<br/><br/>
4.3.1. информацию об использовании Пользователем Сайта, включая информацию о времени и способе использования Сайта, потоке данных и записях элементов экрана, на которые Пользователь нажимает кнопкой мыши в процессе использования Сайта (история посещений), реферер (адрес предыдущей страницы).
<br/><br/>
4.3.2. техническую информацию об устройствах и операционных системах, которые используются в процессе посещения Веб-сайтов, IP-адрес Пользователя и информацию об используемом браузере.
<br/><br/>
5. Обработка персональной информации при помощи файлов Cookie и счетчиков
<br/><br/>
5.1. Файлы cookie, передаваемые Сайтом оборудованию Пользователя и оборудованием Пользователя Сайту, могут использоваться Администрацией сайта для предоставления Пользователю персонализированных услуг, для таргетирования рекламы, которая показывается Пользователю, в статистических и исследовательских целях, а также для улучшения качества услуг.
<br/><br/>
5.2. Пользователь осознает, что оборудование и программное обеспечение, используемые им для посещения сайтов в сети интернет, могут обладать функцией запрещения операций с файлами cookie (для любых сайтов или для определенных сайтов), а также удаления ранее полученных файлов cookie.
<br/><br/>
5.3. Предоставление определенных персонализированных услуг на Сайте возможно лишь при условии, что прием и получение файлов cookie разрешены Пользователем.
<br/><br/>
5.4. Структура файла cookie, его содержание и технические параметры определяются Администрацией сайта и могут изменяться без предварительного уведомления Пользователя.
<br/><br/>
5.5. Счетчики, размещенные на Сайте, могут использоваться для анализа файлов cookie Пользователя, для сбора и обработки статистической информации об использовании Сайта, а также для обеспечения работоспособности Сайта в целом или его отдельных функций.
<br/><br/>
6. Способы обработки и использования персональной информации
<br/><br/>
6.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
<br/><br/>
6.2. В соответствии с применимым правом Администрация сайта вправе использовать адрес электронной почты и контактный телефон Пользователя для предоставления ему информации о продуктах и услугах ООО «Элберт Инвестмент», а также продуктах и услугах, сходных с теми услугами, которые Пользователь запрашивает на Сайте, за исключением случаев, когда Пользователь отказывается от получения упомянутой информации.
<br/><br/>
6.3. Отказаться от использования адреса электронной почты и контактного телефона в указанных целях Пользователь может в любой момент одним из следующих способов:
<br/><br/>
6.3.1. следуя инструкциям по отказу от рассылки в конце электронного письма, полученного от Компании;
<br/><br/>
6.3.2. направив электронное письмо с адреса электронной почты Пользователя по адресу info@pirogovskaya14.com с просьбой не присылать информацию.
<br/><br/>
7. Передача информации Пользователей третьим лицам
<br/><br/>
7.1. Администрация Сайта не передает персональные данные Пользователей третьим лицам, за исключением следующих случаев:
<br/><br/>
7.1.1. с согласия Пользователя;
<br/><br/>
7.1.2. если имеется обязанность передать информацию в соответствии с применимым законодательством, требованиями государственных или правоохранительных органов или в целях предотвращения преступлений;
<br/><br/>
7.1.3. если передача предусмотрена российским или иным применимым законодательством
<br/><br/>
7.1.4. если в результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Компании.
<br/><br/>
8. Меры, применяемые для защиты персональной информации Пользователя
<br/><br/>
8.1. Администрация сайта принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.
<br/><br/>
9. Права Пользователя на отказ от использования персональных данных, изменение, дополнение, уточнение персональных данных
<br/><br/>
9.1. Пользователь вправе в любое время реализовать следующие права в отношении тех данных, которые могут признаваться персональными данными в соответствии с применимым правом:
<br/><br/>
9.1.1. Пользователь вправе обратиться к Администрации сайта с просьбой о предоставлении копии имеющихся у Администрации сайта его персональных данных или сведений о порядке их обработки;
<br/><br/>
9.1.2. Пользователь вправе обратиться к Администрации сайта с просьбой об обновлении и исправлении любых хранящихся у Администрации сайта его персональных данных, если они устарели или являются недостоверными;
<br/><br/>
9.1.3. Пользователь вправе отозвать своё согласие на обработку персональных данных.
<br/><br/>
9.2. Для реализации любого из перечисленных прав Пользователю надлежит обращаться по адресу электронной почты info@pirogovskaya14.com
<br/><br/>
10. Внесение изменений в настоящую Политику
<br/><br/>
10.1 Администрация сайта имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.
    `}}/>

    <Footer dangerouslySetInnerHTML={{__html: 'Площадь апартамента по проектной документации может быть изменена в порядке, предусмотренном 214<br/>ФЗ «Об участии в долевом строительстве» от 30 декабря 2004 года №210-ФЗ.'}}/>
  </Components.Container>
);

export const enhance = compose(
  HOC.withTranslation(data),
);

export default enhance(Documents);
