export default {
  ru: {
    title: 'Документы',
    footer: 'Площадь апартамента по проектной документации может быть изменена в порядке, предусмотренном 214<br/>ФЗ «Об участии в долевом строительстве» от 30 декабря 2004 года №210-ФЗ.',
    documents: [{
      date: '24.04.2018',
      title: 'Разрешение на строительство',
      link: '/static/documents/2018-04-24 Разрешение на строительство.pdf',
      subtitle: 'ООО "Элберт Инвестмент"',
    }, {
      date: '14.05.2012',
      title: 'МП14 Договор аренды земли',
      link: '/static/documents/МП14 Договор аренды земли.pdf',
      subtitle: 'ООО "Элберт Инвестмент"',
    }, {
      date: '14.05.2012',
      title: 'МП14 Допсоглашение к Договору аренды земли.pdf',
      link: '/static/documents/МП14 Допсоглашение к Договору аренды земли.pdf',
      subtitle: 'ООО "Элберт Инвестмент"',
    }, {
      date: '19.03.2018',
      title: 'ООО Элберт инвестмент аудиторское заключение',
      link: '/static/documents/ООО Элберт инвестмент аудиторское заключение.pdf',
      subtitle: 'ООО "Элберт Инвестмент"',
    }],
  },
  en: {
    title: 'Документы',
    footer: 'Площадь апартамента по проектной документации может быть изменена в порядке, предусмотренном 214<br/>ФЗ «Об участии в долевом строительстве» от 30 декабря 2004 года №210-ФЗ.',
    documents: [{
      date: '24.04.2018',
      title: 'Разрешение на строительство',
      link: '/static/documents/2018-04-24 Разрешение на строительство.pdf',
      subtitle: 'ООО "Элберт Инвестмент"',
    }, {
      date: '14.05.2012',
      title: 'МП14 Договор аренды земли',
      link: '/static/documents/МП14 Договор аренды земли.pdf',
      subtitle: 'ООО "Элберт Инвестмент"',
    }, {
      date: '14.05.2012',
      title: 'МП14 Допсоглашение к Договору аренды земли.pdf',
      link: '/static/documents/МП14 Допсоглашение к Договору аренды земли.pdf',
      subtitle: 'ООО "Элберт Инвестмент"',
    }, {
      date: '19.03.2018',
      title: 'ООО Элберт инвестмент аудиторское заключение',
      link: '/static/documents/ООО Элберт инвестмент аудиторское заключение.pdf',
      subtitle: 'ООО "Элберт Инвестмент"',
    }],
  },
};
